import { useFormikContext } from "formik";
import { isArray } from "lodash-es";
import { Fragment } from "react";
import { Row } from "reactstrap";
import data, { dataLegend } from "./data";
import ReactTooltip from "react-tooltip";

const SelectedFields = () => {
  const context = useFormikContext();

  const isFieldSelected = Boolean(Object.values(context.values).filter((e) => isArray(e)).length);

  return (
    <div className="selected-fields">
      <Row>
        <Title />
        {!isFieldSelected ? <div className="selected-fields__no-select">You have no fields selected</div> : null}
        <SelectedBlock data={data} context={context} />
      </Row>
    </div>
  );
};

export default SelectedFields;

const Title = () => {
  return <h2 className="form-title">Selected items</h2>;
};

const SelectedBlock = ({ data, context }) => {
  return (
    <>
      {data.map((item, index) => {
        return (
          <Fragment key={index}>
            {context.values[item.name]?.length ? (
              <div className="col-lg-4 col-md-6 selected-fields__block">
                <h3 className="selected-fields__sub-title">{item.name}</h3>
                <SelectedItems blockName={item.name} data={item} context={context} />
              </div>
            ) : null}
          </Fragment>
        );
      })}
    </>
  );
};

const SelectedItems = ({ data, context, blockName }) => {
  return (
    <>
      {data.data.map((item, index) => {
        return (
          <Fragment key={index}>
            {item.fields.map((field, ind) => {
              return field.subTitle ? (
                <Fragment key={ind}>
                  {field.subFields.map((subFields, number) => {
                    return (
                      <Fragment key={number}>
                        {context.values[blockName].includes(subFields.title) && (
                          <SelectedItemsField
                            title={subFields.title}
                            containerType={subFields.beschikbare_inzendmaterialen}
                          />
                        )}
                      </Fragment>
                    );
                  })}
                </Fragment>
              ) : (
                <Fragment key={ind}>
                  {context.values[blockName].includes(field.title) && (
                    <SelectedItemsField title={field.title} containerType={field.beschikbare_inzendmaterialen} />
                  )}
                </Fragment>
              );
            })}
          </Fragment>
        );
      })}
    </>
  );
};

const SelectedItemsField = ({ title, containerType }) => {
  return (
    <div className="selected">
      <div className="selected.title">{title}</div>
      <ContainerLetter containerType={containerType} />
    </div>
  );
};

const ContainerLetter = ({ containerType }) => {
  return (
    <>
      {containerType && (
        <div className="selected-fields__beschikbare_inzendmaterialen--wrapper">
          {containerType.map((item, index) => {
            return <Letter key={index} letter={item} />;
          })}
        </div>
      )}
    </>
  );
};

const Letter = ({ letter }) => {
  const description = dataLegend[letter];

  return (
    <>
      <div data-tip={description} className="selected-fields__beschikbare_inzendmaterialen">
        {letter}
      </div>
      <ReactTooltip className="tooltip" border type="light" effect="solid" />
    </>
  );
};
