import { Field, useFormikContext } from "formik";
import classnames from "classnames";

const CheckField = ({ data, name }) => {
  const context = useFormikContext();
  const isChecked = context.values[name]?.includes(data.title);

  const labelClasses = classnames("check-box", "input-wrapper", "col-lg-4", "col-md-6", {
    active: isChecked,
  });

  return (
    <label className={labelClasses}>
      <Field
        onChange={(e) => {
          context.handleChange(e);
        }}
        className="check-box__field"
        id={data.id}
        type="checkbox"
        name={name}
        value={data.title}
      />
      <div className="input-label">{data.title ? <div className="input-title">{data.title}</div> : null}</div>
      {/* {data.beschikbare_inzendmaterialen && (
        <div className="beschikbare_inzendmaterialen">
          {data.beschikbare_inzendmaterialen.map((item, index) => {
            return <span key={index}>{item}</span>;
          })}
        </div>
      )} */}
    </label>
  );
};

export default CheckField;
