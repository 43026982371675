import { Col, Row } from "reactstrap";
import logo from "../../Images/logo.png";
import "./Header.scss";

const Header = () => {
  return (
    <header className="container header">
      <Row>
        <Col>
          <div className="logo">
            <img className="logo-image" alt="logo" src={logo} />
          </div>
        </Col>
      </Row>
    </header>
  );
};

export default Header;
