import { Field, useFormikContext } from "formik";
import { Col } from "reactstrap";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const InputField = ({ label, type, col, name, date }) => {
  const [startDate, setStartDate] = useState(new Date());
  const context = useFormikContext();

  return (
    <Col md={col} className="input-wrapper input-field__wrapper">
      <label className="input-title">{label}</label>
      {date !== undefined ? (
        <DatePicker
          value={context.values[name]}
          onChange={(e) => {
            setStartDate(e);
            context.setFieldValue(date, startDate);
          }}
          className="input__field"
          selected={startDate}
        />
      ) : (
        <Field value={context.values[name]} name={name} as={type ? type : null} className="input__field" />
      )}
    </Col>
  );
};

export default InputField;
