import React from "react";
import Header from "./components/Header/Header.jsx";
import MedicalForm from "./components/MedicalForm.jsx";
import "./App.scss";

function App() {
  return (
    <div className="App">
      <Header />
      <MedicalForm />
    </div>
  );
}

export default App;
