import { Col, Row } from "reactstrap";
import CheckField from "./CheckField";
import data from "./data.jsx";

const AnalysisUnit = () => {
  return data.map((item, index) => {
    return (
      <Row className="analysis-unit" key={index}>
        <h2 className="analysis-unit__form-title">{item.name}</h2>
        <Col xs="12">
          <AnalysisBody name={item.name} data={item.data} />
        </Col>
        {item.description && (
          <Col className="analysis-unit__description" xs="12">
            <h4 className="analysis-unit__description-title">{item.description.title}</h4>
            <div>{item.description.text}</div>
          </Col>
        )}
      </Row>
    );
  });
};

const AnalysisBody = ({ data, name }) => {
  return data.map((item, index) => {
    return (
      <Row className="analysis-unit--wrapper checkbox-grup" key={index}>
        <Title title={item.title} />
        <FieldGroup name={name} data={item} />
      </Row>
    );
  });
};

const FieldGroup = ({ data, name }) => {
  return data.fields.map((fields, i) => {
    return fields.subTitle ? (
      <SubCheckField name={name} key={i} fields={fields} index={i} />
    ) : (
      <CheckField name={name} key={i} data={fields} index={i} />
    );
  });
};

const SubCheckField = ({ name, fields }) => {
  return (
    <Col xs="12" className="analysis-unit--wrapper">
      <Row className="checkbox-grup">
        <SubBlockTitle title={fields.subTitle} />

        {fields.subFields.map((subFields, ind) => {
          return <CheckField name={name} key={ind} data={subFields} index={ind} />;
        })}
      </Row>
    </Col>
  );
};

const Title = ({ title }) => {
  return title && <h4 className="analysis-unit__sub-block-title">{title}</h4>;
};

const SubBlockTitle = ({ title }) => {
  return title && <h5 className="analysis-unit__sub-title">{title}</h5>;
};

export default AnalysisUnit;
