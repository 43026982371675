import { Container, Row, Col } from "reactstrap";
import { Formik, Form } from "formik";
import InputField from "./InputField";
import SubmitButton from "./SubmitButton";
import "./MedicalForm.scss";
import AnalysisUnit from "./AnalysisUnit";
import SelectedFields from "./SelectedFields";

const MedicalForm = () => {
  const field = {
    date: "Datum afname",
    immuneDisorder: "Afweerstoornis, te weten",
    dayOfIllness: "1e ziektedag",
    antibiotic: "Antibioticum na afname kweek",
    timeDecrease: "Tijd afname",
    pregnantWeeks: "Zwanger, wkn",
    antibiotic2w: "Welk antibioticum gebruik (<2 mnd), sinds wanneer",
    clinicalDataIndication: "Klinische gegevens/indicatie",
  };

  const initialValues = {
    Datum_afname: new Date(),
    Afweerstoornis_te_weten: "",
    one_ziektedag: new Date(),
    Antibioticum_na_afname_kweek: "",
    Tijd_afname: "",
    Zwanger_wkn: "",
    Welk_antibioticum_gebruik_sinds_wanneer: "",
    Klinische_gegevens_indicatie: "",
  };

  function onSubmit(values, actions) {
    actions.resetForm();
    actions.setStatus("submited");

    setTimeout(() => {
      actions.setStatus(undefined);
    }, 5000);
  }

  return (
    <Container className="medical-form">
      <Row>
        <h2 className="form-title">Klinische gegevens, volledig invullen:</h2>
        <Col>
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            <Form>
              <Row className="medical-information__wrapper">
                <InputField date="date" col="6" name="date" label={field.date} />
                <InputField col="6" name="timeDecrease" label={field.timeDecrease} />
                <InputField col="6" name="pregnantWeeks" label={field.pregnantWeeks} />
                <InputField date="dayOfIllness" col="6" name="dayOfIllness" label={field.dayOfIllness} />
                <InputField col="6" name="immuneDisorder" type="textarea" label={field.immuneDisorder} />
                <InputField col="6" name="antibiotic2w" type="textarea" label={field.antibiotic2w} />
                <InputField col="6" name="antibiotic" type="textarea" label={field.antibiotic} />
                <InputField
                  col="6"
                  name="clinicalDataIndication"
                  type="textarea"
                  label={field.clinicalDataIndication}
                />
              </Row>
              <AnalysisUnit />
              <SelectedFields />
              <SubmitButton />
            </Form>
          </Formik>
        </Col>
      </Row>
    </Container>
  );
};

export default MedicalForm;
