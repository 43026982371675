const data = [
  {
    name: "Urineweginfecties",
    data: [
      {
        title: "Materiaal en herkomst",
        fields: [
          {
            id: "27dab931-dd92-4e98-924b-37a89046b2fa",
            title: "Urine (midstroom)",
            beschikbare_inzendmaterialen: ["C"],
          },
          {
            id: "6caeae34-af66-4db3-8527-7b2623927789",
            title: "Urine d.m.v. eenmalige katheter",
            beschikbare_inzendmaterialen: ["C"],
          },
          {
            id: "f736327e-870c-11eb-8dcd-0242ac130003",
            title: "Urine d.m.v. stoma bricker",
            beschikbare_inzendmaterialen: ["C"],
          },
          {
            id: "fdff6a4e-870c-11eb-8dcd-0242ac130003",
            title: "Urine uit verblijfskatheter",
            beschikbare_inzendmaterialen: ["C"],
          },
          {
            id: "a75fa159-97b5-4dbc-abe1-63622f27e9c7",
            title: "Urine na verwijdering verblijfskatheter",
            beschikbare_inzendmaterialen: ["C"],
          },
          {
            id: "d9c10d4e-e284-445c-a206-8d07c054f248",
            title: "Uricult (midstroom)",
          },
        ],
      },
      {
        title: "Gewenst onderzoek",
        fields: [
          {
            id: "32bf49a0-9b3d-4a31-a775-80cafa6fdd7e",
            title: "Algemeen bacteriologisch",
            beschikbare_inzendmaterialen: ["C"],
          },
        ],
      },
    ],
  },
  {
    name: "Maag-darm infecties (feces)",
    data: [
      {
        title: "",
        fields: [
          {
            id: "fd1ce074-1aec-4d42-9d31-b4fb81085e12",
            title: "Bacteriologisch moleculair panel   (z.o.z.)",
            beschikbare_inzendmaterialen: ["A"],
          },
          {
            id: "15d446e9-3187-4c25-aae7-a4241db159ae",
            title: "Parasitologisch moleculair panel   (z.o.z.)",
            beschikbare_inzendmaterialen: ["A", "B"],
          },
          {
            id: "7750a8dd-a039-410f-bc6e-cfc65f84481c",
            title: "Clostridium difficile toxine",
            beschikbare_inzendmaterialen: ["A"],
          },
          {
            id: "62ed36ce-610d-4c15-87a6-85f4f5ec6bad",
            title: "Helicobacter pylori antigeen",
            beschikbare_inzendmaterialen: ["A"],
          },
          {
            id: "96991f97-4848-4e4b-829b-f38d663257b7",
            title: "Norovirus (feces/braaksel)",
            beschikbare_inzendmaterialen: ["A"],
          },
          {
            id: "4768d0ed-aba8-46b8-bdb3-491921913c75",
            title: "Rotavirus / Adenovirus",
            beschikbare_inzendmaterialen: ["A"],
          },
          {
            id: "726ee403-2398-4273-955c-c6505af5365d",
            title: "Darmbacteriën nacontrole op:",
            beschikbare_inzendmaterialen: ["A"],
          },
        ],
      },
    ],
    description: {
      title: "Aanvullend parasitologisch onderzoek",
      text:
        "Parasitologisch onderzoek (o.a. wormeieren(sub)tropencontact, eosinofilie en/of jeuk). BZie Aanvraagformulier parasitologisch onderzoek op www.labmicta.nl",
    },
  },
  {
    name: "Genitale infecties (incl SOA)",
    data: [
      {
        title: "Materiaal en herkomst",
        fields: [
          {
            id: "34816011-d199-409f-98a0-13b0b59b6964",
            title: "Urine (eerste stroom)",
            beschikbare_inzendmaterialen: ["D"],
          },
          {
            id: "beec6327-1b0a-42a3-a8dd-273bbb53142a",
            title: "Cervix uitstrijk",
            beschikbare_inzendmaterialen: ["E"],
          },
          {
            id: "eecfa27d-a6ae-4179-ae89-eb888493148c",
            title: "Vagina uitstrijk",
            beschikbare_inzendmaterialen: ["E"],
          },
          {
            id: "ba64d351-22e0-4188-be23-bbff7fff8861",
            title: "Uitstrijk (overig) herkomst:",
            beschikbare_inzendmaterialen: ["E", "F"],
          },
        ],
      },
      {
        title: "Gewenst onderzoek",
        fields: [
          {
            id: "25dc23c3-fdf1-461d-9c8c-a9c0628cce08",
            title: "Algemeen bacteriologisch",
            beschikbare_inzendmaterialen: ["D"],
          },
          {
            id: "6966100b-f403-4d50-b44b-c2e30559a74b",
            title: "Gisten",
            beschikbare_inzendmaterialen: ["E"],
          },
          {
            id: "ce38b653-b405-4fbe-b1c1-5c767dfce8c9",
            title: "Groep B streptokokken bij zwangeren (rectovaginaal)",
            beschikbare_inzendmaterialen: ["E"],
          },
          {
            id: "043509bd-ca0c-465e-a85f-de46f2776f6c",
            title: "Moleculair SOA panel  (z.o.z.)",
            beschikbare_inzendmaterialen: ["D", "E", "F"],
          },
          {
            subTitle: "Specifieke SOA aanvraag",
            subFields: [
              {
                id: "1c257a94-a6f8-4cc5-a830-3c3d18168588",
                title: "Chlamydia trachomatis",
                beschikbare_inzendmaterialen: ["D", "E", "F"],
              },
              {
                id: "a51e6a1c-44fa-4f4d-977e-224a76c1216e",
                title: "Neisseria gonorrhoeae",
                beschikbare_inzendmaterialen: ["D", "E", "F"],
              },
              {
                id: "53131a89-c0d6-45c2-958d-5fedcfca2af3",
                title: "Trichomonas vaginalis",
                beschikbare_inzendmaterialen: ["D", "E", "F"],
              },
              {
                id: "4e1b10cc-b1a5-4943-acf7-232f9bc725b2",
                title: "Mycoplasma genitalium",
                beschikbare_inzendmaterialen: ["D", "E", "F"],
              },
              {
                id: "9ca33b5c-9ee0-4fcf-a70f-2dc6f56023c7",
                title: "Herpes simplex (blaasjesvocht)",
                beschikbare_inzendmaterialen: ["E", "F"],
              },
              {
                id: "0bfa77eb-e44a-4d7b-ac6c-7c04efbc3567",
                title: "Voor bloedonderzoek SOA zie rechter kolom",
                beschikbare_inzendmaterialen: ["K"],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Luchtweginfecties ",
    data: [
      {
        title: "Materiaal en herkomst",
        fields: [
          {
            id: "c689bf01-ff5f-4a92-b63b-183c00fe6b59",
            title: "Sputum",
            beschikbare_inzendmaterialen: ["I"],
          },
          {
            id: "464b60b6-0986-4622-b8a3-dde6e7b96770",
            title: "Uitstrijk, herkomst",
            beschikbare_inzendmaterialen: ["E", "F"],
          },
          {
            id: "20c85e5a-2325-4cc2-bef3-2ef782a6600e",
            title: "Urine",
            beschikbare_inzendmaterialen: ["D"],
          },
          {
            id: "e52efaf1-de31-4200-be6d-67a96657a8b5",
            title: "Legionella pneumophila sneltest",
            beschikbare_inzendmaterialen: ["D"],
          },
          {
            id: "9620b9f8-01f9-4528-a572-2338786e3473",
            title: "Pneumokokken sneltest",
            beschikbare_inzendmaterialen: ["D"],
          },
        ],
      },
      {
        title: "Gewenst onderzoek",
        fields: [
          {
            id: "fcb12fde-b546-4a92-b97e-121ac169c7b8",
            title: "Algemeen bacteriologisch",
            beschikbare_inzendmaterialen: ["I", "E"],
          },
          {
            id: "f8153fb1-8fa6-4ab1-9c24-c7e6f34835fe",
            title: "Moleculair respiratoir panel (z.o.z.)",
            beschikbare_inzendmaterialen: ["I", "E"],
          },
          {
            id: "b2db4c09-ba33-4d49-9279-78aca1e18572",
            title: "Influenza virus",
            beschikbare_inzendmaterialen: ["I", "E"],
          },
          {
            id: "e2ca7a30-654d-46ee-a6f6-5496b422cd4e",
            title: "Bordetella (para)pertussis (kinkhoest)",
            beschikbare_inzendmaterialen: ["F"],
          },
          {
            id: "3485d29e-55ab-497b-a5ca-8bca3fcab369",
            title: "Legionella spp (uit sputum)",
            beschikbare_inzendmaterialen: ["I"],
          },
          {
            id: "e03db014-086a-4c30-b4d6-cf4e761f93e3",
            title: "Respiratoir syncytieel virus (RSV)",
            beschikbare_inzendmaterialen: ["I", "E"],
          },
          {
            id: "fb0abc00-ef61-4ea1-87a4-d39a14dc4324",
            title: "Mycobacteriën tuberculose (TBC)",
            beschikbare_inzendmaterialen: ["I"],
          },
        ],
      },
    ],
  },
  {
    name: "Overige infecties",
    data: [
      {
        title: "Materiaal en herkomst",
        fields: [
          {
            id: "35bb140e-3a6e-4915-bc44-eb41ed529c4f",
            title: "Wonduitstrijk, herkomst",
            beschikbare_inzendmaterialen: ["E", "F"],
          },
          {
            id: "0f483e69-ac1e-4c18-a03d-f6c4e1eab3f3",
            title: "Uitstrijk, herkomst:",
            beschikbare_inzendmaterialen: ["E", "F"],
          },
          {
            id: "36d972c2-289c-43c8-97bd-37e96cca72e2",
            title: "Vloeibare pus, herkomst:",
            beschikbare_inzendmaterialen: ["J"],
          },
          {
            id: "8ae503a5-1b31-43e5-b19f-37b48c5a2c4a",
            title: "Haar / Huidschilfers / Nagel",
            beschikbare_inzendmaterialen: ["M"],
          },
          {
            id: "4b89fe86-2463-47cb-a015-74a03386fb86",
            title: "Huidschilfers i.v.m. Scabiës",
            beschikbare_inzendmaterialen: ["M", "I"],
          },
        ],
      },
      {
        title: "Gewenst onderzoek",
        fields: [
          {
            id: "fb5d1f25-4da9-4736-9dcc-697c757896d9",
            title: "Algemeen bacteriologisch",
            beschikbare_inzendmaterialen: ["E", "J"],
          },
          {
            id: "4e3ee041-0176-4d5f-bcf1-292098183693",
            title: "Ectoparasieten (o.a. luizen, mijten, teken, larven)",
            beschikbare_inzendmaterialen: ["I"],
          },
          {
            id: "1c62472e-7c7a-47db-9702-db47010e79ee",
            title: "Gisten",
            beschikbare_inzendmaterialen: ["E", "J", "M"],
          },
          {
            id: "1896fe06-2c98-496f-94c0-e784e8b77c47",
            title: "Herpes simplex (blaasjesvocht)",
            beschikbare_inzendmaterialen: ["E", "F"],
          },
          {
            id: "7ba770f7-d17e-40f8-9616-59cd5c64edc5",
            title: "Mycobacteriën tuberculose (TBC)",
            beschikbare_inzendmaterialen: ["J"],
          },
          {
            id: "cf6a4300-039f-4571-9555-5665c59a2b37",
            title: "Mycobacteriën overige (NTM)",
            beschikbare_inzendmaterialen: ["J"],
          },
          {
            id: "fc27ae01-9123-447a-8a1b-2bbf92ab20fe",
            title: "Moleculair dermatofyten panel (z.o.z.)",
            beschikbare_inzendmaterialen: ["M"],
          },
          {
            id: "71c67521-acb0-49b9-ae05-f5ea7555e51f",
            title: "Schimmels",
            beschikbare_inzendmaterialen: ["E", "J"],
          },
          {
            id: "8d8e10c2-714b-49c6-80e0-dc36aff53a7b",
            title: "Varicella zoster virus (blaasjesvocht)",
            beschikbare_inzendmaterialen: ["E", "F"],
          },
        ],
      },
    ],
  },
  // { name: "Materiaal / gevraagd onderzoek niet op formulier:", data: [{ title: "", fields: [] }] },
  {
    name: "Bloedonderzoek",
    data: [
      {
        title: "Materiaal en herkomst",
        fields: [
          {
            id: "8e19023a-9b8d-4a37-a291-242327809075",
            title: "1e monster",
          },
          {
            id: "688e86bd-70c8-4de7-b720-d1a61d747bb5",
            title: "2e monster",
          },
          {
            id: "7cee71ea-ee8c-4b6d-a2ac-32f998b16d31",
            title: " spijt/restmonster",
          },
          {
            id: "2a8a4c86-4379-4913-b1ec-30c175a90d79",
            title: "Bloed/serum (stolbuis)",
            beschikbare_inzendmaterialen: ["K"],
          },
          {
            id: "0f876739-6b1b-4b31-b914-da5b2deacbb2",
            title: "Bloed (EDTA)",
            beschikbare_inzendmaterialen: ["L"],
          },
        ],
      },
      {
        title: "Gewenst onderzoek",
        fields: [
          {
            subTitle: "Artritis",
            subFields: [
              {
                id: "2a91bca2-1d49-4c5e-acfa-d3649c96d78e",
                title: "Artritis reeks (z.o.z.)",
                beschikbare_inzendmaterialen: ["K"],
              },
            ],
          },
          {
            subTitle: "Hepatitis",
            subFields: [
              {
                id: "a1680af8-a6ce-4af4-8882-85fc1a28cce3",
                title: "Lever reeks (z.o.z)",
                beschikbare_inzendmaterialen: ["K"],
              },
              {
                id: "da93e9ad-eb56-49e2-a87d-3b13364269f3",
                title: "Hepatitis A (verdenking infectie)",
                beschikbare_inzendmaterialen: ["K"],
              },
              {
                id: "234f6fca-85da-4a7c-a333-5772e26421cf",
                title: "Hepatitis A (immuunstatus, na vaccinatie)",
                beschikbare_inzendmaterialen: ["K"],
              },
              {
                id: "bed20ec9-40ae-436b-8b78-d1d93189055f",
                title: "Hepatitis B (verdenking infectie)",
                beschikbare_inzendmaterialen: ["K"],
              },
              {
                id: "9e1160eb-b7db-4912-917e-96dccf1b00b1",
                title: "Hepatitis B (immuunstatus, na vaccinatie)",
                beschikbare_inzendmaterialen: ["K"],
              },
              {
                id: "6730cb07-4086-481e-a1f5-3c253d392ccf",
                title: "Hepatitis C",
                beschikbare_inzendmaterialen: ["K"],
              },
              {
                id: "4d3227e0-0c29-4fc5-97a9-62769740d229",
                title: "Hepatitis E (serologie + PCR)",
                beschikbare_inzendmaterialen: ["K"],
              },
              {
                id: "b4092ab8-d36c-41ab-b0e1-309eef8a1206",
                title: "HCV kwantitatief (Viral load HCV)",
                beschikbare_inzendmaterialen: ["L"],
              },
              {
                id: "6de0298c-1b65-4411-b8bc-ac3c3dd4619d",
                title: "HBV kwantitatief (Viral load HBV)",
                beschikbare_inzendmaterialen: ["L"],
              },
            ],
          },
          {
            subTitle: "Lymfadenopathie",
            subFields: [
              {
                id: "a42b818a-6a22-4e48-917f-64c3fdc2cf87",
                title: "Lymfadenopathie reeks (z.o.z)",
                beschikbare_inzendmaterialen: ["K"],
              },
              {
                id: "5286cfc7-0507-48b0-bfb5-fe4e1978fee8",
                title: "Bartonella (kattenkrabziekte)",
                beschikbare_inzendmaterialen: ["K"],
              },
            ],
          },
          {
            subTitle: "SOA",
            subFields: [
              {
                id: "082f15d0-4c0d-4e22-b20d-88f53de8f4e6",
                title: "Hepatitis B virus (verdenking infectie)",
                beschikbare_inzendmaterialen: ["K"],
              },
              {
                id: "4f980596-e517-4adb-9e0e-00a0c505839d",
                title: "HIV 1 en 2",
                beschikbare_inzendmaterialen: ["K"],
              },
              {
                id: "fa941776-1975-4e6c-9a5f-9e078e985089",
                title: "Treponema pallidum (lues/syfilis)",
                beschikbare_inzendmaterialen: ["K"],
              },
            ],
          },
          {
            subTitle: "Overige bloedonderzoeken op alfabet",
            subFields: [
              {
                id: "8a40d6de-17a2-4b48-8c02-b31dd13d0143",
                title: "AST",
                beschikbare_inzendmaterialen: ["K"],
              },
              {
                id: "48672554-7f00-4882-b83d-962c7b409067",
                title: "Bordetella pertussis (kinkhoest) (z.o.z.)",
                beschikbare_inzendmaterialen: ["K"],
              },
              {
                id: "cc4074fc-9249-4c2c-8d11-d1dac5178606",
                title: "Borrelia burgdorferi serologie (Lyme)*K*NB: vermeld altijd klinische gegevens",
                beschikbare_inzendmaterialen: ["K"],
              },
              {
                id: "e922f8f7-12e5-4ef3-90c8-cdf5646cd191",
                title: "Bof virus (parotitis)",
                beschikbare_inzendmaterialen: ["K"],
              },
              {
                id: "ffb19442-9bd6-41d4-b605-2e463fef3cab",
                title: "Chlamydophila psittacci (ornithose) ",
                beschikbare_inzendmaterialen: ["K"],
              },
              {
                id: "a87140ec-3b93-4f57-8bd8-af4732f27e94",
                title: "Cytomegalovirus serologie (CMV)",
                beschikbare_inzendmaterialen: ["K"],
              },
              {
                id: "aa46890f-df81-41ed-a400-b6053eba7eda",
                title: "Epstein-Barr virus (EBV)",
                beschikbare_inzendmaterialen: ["K"],
              },
              {
                id: "63d03ee2-bb49-4450-8bcf-1e8e2d5bd586",
                title: "Hantavirus",
                beschikbare_inzendmaterialen: ["K"],
              },
              {
                id: "24831767-cb81-47f8-b374-30cea285270b",
                title: "Helicobacter pylori antistoffen",
                beschikbare_inzendmaterialen: ["K"],
              },
              {
                id: "9b451bab-96fe-4013-9d1f-389cc1bf2264",
                title: "Leptospirose ziekteduur ≤ 10 dagen",
                beschikbare_inzendmaterialen: ["K"],
              },
              {
                id: "b1b87780-0b0d-4894-85a5-a3c9bf77fde1",
                title: "Leptospirose ziekteduur > 10 dagen",
                beschikbare_inzendmaterialen: ["K"],
              },
              {
                id: "83a28569-5f0a-4063-aee6-0af2572a8975",
                title: "Mazelen virus",
                beschikbare_inzendmaterialen: ["K"],
              },
              {
                id: "f74d1b1a-f1ea-4e7e-bb23-6ff7317bb63f",
                title: "Mycoplasma pneumonie",
                beschikbare_inzendmaterialen: ["K"],
              },
              {
                id: "30db811a-4dcc-4b1f-86e6-33e3da2f63a3",
                title: "Parvo B19 virus (5e ziekte)",
                beschikbare_inzendmaterialen: ["K"],
              },
              {
                id: "57f5a862-6b0d-497b-ac25-936995390ba7",
                title: "Q-koorts (1e ziektedag < 21 dagen) (z.o.z.)",
                beschikbare_inzendmaterialen: ["K"],
              },
              {
                id: "138f9600-186a-44ee-b868-4aa3a169df16",
                title: "Q-koorts (1e ziektedag > 21 dagen)",
                beschikbare_inzendmaterialen: ["K"],
              },
              {
                id: "32393a40-84f1-424d-9c73-4c4891d312a8",
                title: "Q-koorts (chronisch)  (z.o.z.)",
                beschikbare_inzendmaterialen: ["K"],
              },
              {
                id: "bec7623e-05c1-4718-a0e5-a32123d57d7c",
                title: "Rubella (rode hond) (immuunstatus/zwanger)",
                beschikbare_inzendmaterialen: ["K"],
              },
              {
                id: "d8fe8f20-c209-4e49-ae64-d0009ea22245",
                title: "Rubella (rode hond) (infectie)",
                beschikbare_inzendmaterialen: ["K"],
              },
              {
                id: "1c0a24a3-de20-4bee-a903-5c17a968eca0",
                title: "Tekenencefalitis (TBE)",
                beschikbare_inzendmaterialen: ["K"],
              },
              {
                id: "ce3b88ba-5105-4489-b172-9a4d864cea60",
                title: "Toxoplasma gondii",
                beschikbare_inzendmaterialen: ["K"],
              },
              {
                id: "47ed6c28-5707-44d4-884d-71e493a41a7c",
                title: "Varicella zoster virus (immuunstatus/zwanger)",
                beschikbare_inzendmaterialen: ["K"],
              },
              {
                id: "f8016929-9d1f-42ee-9aab-1768a2c0e1f8",
                title: "Varicella zoster virus (infectie)",
                beschikbare_inzendmaterialen: ["K"],
              },
            ],
          },
        ],
      },
    ],
  },
];

export default data;

export const dataLegend = {
  A: "Feces",
  B: "DFT",
  C: "Urine",
  D: "Urine (MSP)",
  E: "E-swab Regular (roze dop)",
  F: "E-swab XS (oranje dop)",
  G: "E-swab Regular MRSA",
  I: "Sputum/steriele container",
  J: "Punctaat",
  K: "Bloed-serologie (stolbuis)",
  L: "Bloed-serologie (EDTA: binnen 6 uur op het lab)",
  M: "Dermapak",
  N: "Plakbandpreparaat",
};
