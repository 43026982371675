import { Row, Col } from "reactstrap";
import { useFormikContext } from "formik";

const SubmitButton = () => {
  const context = useFormikContext();
  return (
    <Row>
      {context.status === "submited" ? <div className="successfully-submitted">Successfully submitted</div> : null}
      <Col className="col-12">
        <button className="submit-button btn" type="submit" disabled={context.dirty === false}>
          Submit
        </button>
      </Col>
    </Row>
  );
};

export default SubmitButton;
